import * as React from 'react'
import ChartGrid from './ChartGrid'
import { Bar } from 'react-chartjs-2'
import { calculatorStore, formatPercentage, getCellData } from './store'

export default function TaxabaleEquiavalentChart() {
  const { deposit, annuityRate, taxRate, term } = calculatorStore()
  const [chartData, setChartData] = React.useState([0, 0])
  const [taxableYield, setTaxableYield] = React.useState(0)

  React.useEffect(() => {
    if (typeof document === 'undefined') return

    const TEY = getCellData('TEY')
    setChartData([annuityRate, TEY])
    setTaxableYield(TEY - annuityRate)

    return () => setChartData([annuityRate, 0])
  }, [deposit, annuityRate, taxRate, term])

  return (
    <ChartGrid
      title="Taxable Yield Required for Equivalent Return"
      dt="What additional yield would you need from a taxable investment?"
      dd={formatPercentage(taxableYield, 1)}
      className="taxable-yield"
    >
      <Bar
        data={{
          labels: ['Tax-Deferred Annuity', 'Taxable Equivalent Yield'],
          datasets: [
            { data: chartData, backgroundColor: ['#00609e', '#74cef5'] },
          ],
        }}
        options={{
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            y: {
              ticks: {
                callback: (value) => formatPercentage(value, 1),
              },
            },
          },
        }}
        maintainAspectRatio={false}
        height={80}
      />
    </ChartGrid>
  )
}
