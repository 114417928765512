import * as React from 'react'
import ChartGrid from './ChartGrid'
import { Bar } from 'react-chartjs-2'
import { calculatorStore, formatMoney, getCellData } from './store'

export default function TaxDeferralChart({ money_saved_copy }) {
  const { deposit, annuityRate, taxRate, term } = calculatorStore()
  const [chartData, setChartData] = React.useState([0, 0])
  const [moneySaved, setMoneySaved] = React.useState(0)

  React.useEffect(() => {
    if (typeof document === 'undefined') return

    const TDAG = getCellData('TDAG_f') - getCellData('TDAG_i')
    const SGTA = sumSGTA()

    setChartData([TDAG, SGTA])
    setMoneySaved(TDAG - SGTA)

    return () => setChartData([0, 0])
  }, [deposit, annuityRate, taxRate, term])

  return (
    <ChartGrid
      title="The Value of Tax Deferral"
      dt={money_saved_copy}
      dd={formatMoney(moneySaved)}
      className="tax-deferral"
    >
      <Bar
        data={{
          labels: ['Tax-Deferred Annuity Gain', 'Same Gain if Taxed Annually'],
          datasets: [
            { data: chartData, backgroundColor: ['#4ea72e', '#8ee066'] },
          ],
        }}
        options={{
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            y: {
              ticks: {
                callback: (value) => formatMoney(value, 0),
              },
            },
          },
        }}
        maintainAspectRatio={false}
        height={80}
      />
    </ChartGrid>
  )
}

function sumSGTA() {
  const arr = Array.from(document.querySelectorAll('table .net-gain'))
  const sum =
    arr.reduce((acc, curr) => acc + (Number(curr?.dataset?.value) || 0), 0) ?? 0

  return sum
}
