import * as React from 'react'
import { IoMdCheckmark } from 'react-icons/io'
import { MdOutlineContentCopy } from 'react-icons/md'

export default function CopyReportLink() {
  const [isCopied, setIsCopied] = React.useState(false)

  function copyReportLink() {
    navigator.clipboard.writeText(window.location.href)

    setIsCopied(true)

    const timeout = setTimeout(() => {
      setIsCopied(false)
    }, 2000)

    return () => clearTimeout(timeout)
  }

  return (
    <button className="action" onClick={copyReportLink}>
      {isCopied ? (
        <>
          <IoMdCheckmark />
          Copied Report Link!
        </>
      ) : (
        <>
          <MdOutlineContentCopy />
          Copy Report Link
        </>
      )}
    </button>
  )
}
