import { create } from 'zustand'

export const TERMS = [3, 5, 7, 10]

export const calculatorStore = create((set) => ({
  deposit: 200_000,
  setDeposit: (deposit) => set({ deposit }),

  annuityRate: 6,
  setAnnuityRate: (annuityRate) => set({ annuityRate }),

  taxRate: 50,
  setTaxRate: (taxRate) => set({ taxRate }),

  term: TERMS[0],
  setTerm: (term) => set({ term }),

  showTables: true,
  setShowTables: (showTables) => set({ showTables }),
}))

export function formatMoney(value, digits = 2) {
  const { format } = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: digits,
  })

  return format(value)
}

export function formatPercentage(value, digits = 2) {
  const { format } = new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: digits,
  })

  return format(value / 100)
}

export function getCellData(cellId) {
  return Number(document.getElementById(cellId)?.dataset.value ?? 0)
}
