import * as React from 'react'
import * as css from './ChartResults.module.css'
import Chart from 'chart.js/auto'
import { CategoryScale } from 'chart.js'
import clsx from 'clsx'

Chart.register(CategoryScale)

export default function ChartGrid({ title, dt, dd, children, className }) {
  return (
    <article className={clsx(css.root, className)}>
      <h2 className="h2">{title}</h2>

      <div className={css.grid}>
        <div className={css.chart}>{children}</div>

        <dl className={css.savings}>
          <dt className="h3">{dt}</dt>
          <dd className="h1">{dd}</dd>
        </dl>
      </div>
    </article>
  )
}
