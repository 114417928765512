import * as React from 'react'
import DefaultLayout from 'layouts/Default'
import { graphql } from 'gatsby'
import Columns from 'components/page-blocks/Columns'
import HeroWithBg from 'components/common/HeroWithBg'
import Resources from 'components/common/Resources'
import Richtext from 'components/page-blocks/Richtext'
import Team from 'components/common/Team'
import TaxEquivalentCalculator from 'components/page-blocks/TaxEquivalentCalculator'
import VideoEmbed from 'components/page-blocks/VideoEmbed'
import Jotform from 'components/page-blocks/Jotform'

export default function Page({ data }) {
  const { sanityPage: pg } = data

  return (
    <DefaultLayout {...pg.seo}>
      {pg.blocks.map((block, key) => {
        switch (block._type) {
          case 'columns':
            return <Columns {...block} key={key} />
          case 'hero':
            return <HeroWithBg {...block} key={key} />
          case 'resourceList':
            return <Resources {...block} key={key} />
          case 'richtext':
            return (
              <Richtext {...block} className="padding container" key={key} />
            )
          case 'team':
            return <Team {...block} key={key} />
          case 'tax-equivalent-calculator':
            return <TaxEquivalentCalculator {...block} key={key} />
          case 'videoEmbed':
            return (
              <VideoEmbed {...block} className="padding container" key={key} />
            )
          case 'jotform':
            return <Jotform {...block} key={key} />
          default:
            return null
        }
      })}
    </DefaultLayout>
  )
}

export const query = graphql`
  query Page($id: String) {
    sanityPage(id: { eq: $id }) {
      blocks {
        ... on SanityColumns {
          _type
          title
          blocks {
            ... on SanityRichtext {
              _type
              _rawDescription
            }
            ... on SanityImageWithCaption {
              _type
              image {
                asset {
                  gatsbyImageData(placeholder: NONE)
                }
              }
              alt
              caption
            }
            ... on SanityVideoEmbed {
              _type
              title
              url
            }
          }
          columns
          align
        }
        ... on SanityHero {
          _type
          ...hero
        }
        ... on SanityResourceList {
          _type
          title
          resources {
            title
            _rawDescription
          }
        }
        ... on SanityRichtext {
          _type
          _rawDescription
        }
        ... on SanityTeam {
          _type
          title
          team {
            ...employees
          }
        }
        ... on SanityTaxEquivalentCalculator {
          _type
          money_saved_copy
        }
        ... on SanityVideoEmbed {
          _type
          title
          url
        }
        ... on SanityJotform {
          _type
          title
          iframe
        }
      }
      seo {
        title
        description
      }
    }
  }
`
